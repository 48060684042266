
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, STORAGE_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdDelete, MdModeEdit, MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import ModalQuestao from './ModalQuestao';
import ModalCadastroQuestao from './ModalCadastroQuestao';
import { BsFillEyeFill } from 'react-icons/bs';
import { FaListAlt, FaUserCircle } from 'react-icons/fa';
import './style.css';
import { Close } from '@material-ui/icons';
import formatDate from '../../Auxiliar/formatDate';
import ModalFormula from './ModalFormula';
import FormulaFx from '../../assets/images/formula-4.png';


class EditarQuestao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/atribuicao/questoes/',
            loading_screen: false,
            enunciado: '',
            alternativa_a: '',
            alternativa_b: '',
            alternativa_c: '',
            alternativa_d: '',
            alternativa_e: '',
            loading_save: false,
            msgErro: '',
            loading_image_enunciado: false,
            loading_image_a: false,
            loading_image_b: false,
            loading_image_c: false,
            loading_image_d: false,
            loading_image_e: false,
            correta: '',
            corretas: [
                { id: 'a', 'name': 'a)' },
                { id: 'b', 'name': 'b)' },
                { id: 'c', 'name': 'c)' },
                { id: 'd', 'name': 'd)' },
                { id: 'e', 'name': 'e)' },

            ],

            categoria: { name: '' },
            demanda: { name: '' },
            demanda_professor: { name: '' },


            type_questao: 'simples',
            questoes: [],
            show_modal_cadastro: false,
            showModal: false,
            index_select: -1,
            questaoSelect: null,
            escolaridades: [
                'Ensino Fundamental',
                'Ensino Médio',
                'Ensino Superior',
            ],
            dificuldades: [
                'Fácil',
                'Médio',
                'Difícil',
            ],
            palavras_chaves: [],
            escolaridade: '',
            dificuldade: '',
            keyword: '',
            numero_alternativas: [5, 4, 3, 2, 1],
            numero_alternativa: 5,
            type_return: 0,
            comentarios:[],
            statusAprovacao:null,
            solucao:'',
            loading_image_solucao: false,
            showModalFormula:false
        };

        this.load_configs();


    }

    parseQuestao(resp){
        if (resp.questao.type_questao == 'simples') {
            this.setState({
                loading_screen: false,
                categoria: resp.questao.categoria,
                demanda: resp.demanda,
                demanda_professor: resp.demanda_professor,


                alternativa_a: resp.questao.alternativas[0].name,
                alternativa_b: resp.questao.alternativas[1] == undefined ? '' : resp.questao.alternativas[1].name,
                alternativa_c: resp.questao.alternativas[2] == undefined ? '' : resp.questao.alternativas[2].name,
                alternativa_d: resp.questao.alternativas[3] == undefined ? '' : resp.questao.alternativas[3].name,
                alternativa_e: resp.questao.alternativas[4] != undefined ? resp.questao.alternativas[4].name : '',
                enunciado: resp.questao.name,
                correta: resp.questao.correta,
                type_questao: resp.questao.type_questao,
                comentarios: resp.questao.comentarios,
                statusAprovacao:resp.questao.statusAprovacao,
                dificuldade: resp.questao.dificuldade,
                escolaridade: resp.questao.escolaridade,
                palavras_chaves: resp.questao.keywords,
                solucao:resp.questao.solucao ?? '',

                numero_alternativa: resp.questao.alternativas.length == 0 ? 5 : resp.questao.alternativas.length,
            });
        }
        else if (resp.questao.type_questao == 'conjunto') {
            let questoes = [...resp.questao.questoes];
            questoes = questoes.map((item, id) => {
                item.numero_alternativa = item.alternativas.length == 0 ? 5 : item.alternativas.length
                return item;
            })
            this.setState({
                loading_screen: false,
                categoria: resp.questao.categoria,
                demanda: resp.demanda,
                demanda_professor: resp.demanda_professor,

                enunciado: resp.questao.name,
                type_questao: resp.questao.type_questao,
                questoes: questoes,
                dificuldade: resp.questao.dificuldade,
                escolaridade: resp.questao.escolaridade,
                palavras_chaves: resp.questao.keywords,
                statusAprovacao:resp.questao.statusAprovacao,
                comentarios: resp.questao.comentarios,
                solucao:resp.questao.solucao ?? '',

                numero_alternativa: resp.questao.alternativas.length == 0 ? 5 : resp.questao.alternativas.length,
            });
        }
        else if (resp.questao.type_questao == 'dissertativa') {
            this.setState({
                loading_screen: false,
                categoria: resp.questao.categoria,
                demanda: resp.demanda,
                demanda_professor: resp.demanda_professor,
                statusAprovacao:resp.questao.statusAprovacao,
                comentarios: resp.questao.comentarios,

                enunciado: resp.questao.name,
                type_questao: resp.questao.type_questao,
                dificuldade: resp.questao.dificuldade,
                escolaridade: resp.questao.escolaridade,
                palavras_chaves: resp.questao.keywords,
                solucao:resp.questao.solucao ?? '',

                numero_alternativa: resp.questao.alternativas.length == 0 ? 5 : resp.questao.alternativas.length,
            });
        }
    }


    get_questao(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/professor/get_questao/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.parseQuestao(resp);

                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    salvar(e) {
        e.preventDefault();
        document.getElementById('text_enunciado').innerHTML = this.state.enunciado;

        let data = {}
        data.enunciado = this.state.enunciado;
        data.content_enunciado = document.getElementById('text_enunciado').textContent;
        data.categoria_id = this.state.categoria.id;
        data.type_questao = this.state.type_questao;

        data.escolaridade = this.state.escolaridade;
        data.dificuldade = this.state.dificuldade;
        data.palavras_chaves = this.state.palavras_chaves;
        data.solucao=this.state.solucao;


        if (this.state.type_questao == 'simples') {
            data.alternativa_a = this.state.alternativa_a;
            data.alternativa_b = this.state.alternativa_b;
            data.alternativa_c = this.state.alternativa_c;
            data.alternativa_d = this.state.alternativa_d;
            data.alternativa_e = this.state.alternativa_e;
            data.numero_alternativa = this.state.numero_alternativa;

            data.correta = this.state.correta;
            if(this.props.evaluation==true){
                data.alternativas=[];
                data.alternativas.push({
                    name:this.state.alternativa_a
                });
                if(this.state.numero_alternativa>=2){
                    data.alternativas.push({
                        name:this.state.alternativa_b
                    });
                }
                if(this.state.numero_alternativa>=3){
                    data.alternativas.push({
                        name:this.state.alternativa_c
                    });
                }
                if(this.state.numero_alternativa>=4){
                    data.alternativas.push({
                        name:this.state.alternativa_d
                    });
                }
                if(this.state.numero_alternativa>=5){
                    data.alternativas.push({
                        name:this.state.alternativa_e
                    });
                }
            }

        }
        else if (this.state.type_questao == 'conjunto') {
            let questoes = [...this.state.questoes];
            console.log(questoes)
            questoes = questoes.map((item, id) => {
                item = { ...item };
                item.alternativa_a = item.alternativas[0].name;
                if (item.numero_alternativa >= 2) item.alternativa_b = item.alternativas[1].name;
                if (item.numero_alternativa >= 3) item.alternativa_c = item.alternativas[2].name;
                if (item.numero_alternativa >= 4) item.alternativa_d = item.alternativas[3].name;
                if (item.numero_alternativa >= 5) item.alternativa_e = item.alternativas[4].name;


                if(this.props.evaluation!=true){
                    delete item.alternativas;
                }
                item.categoria_id = item.categoria.id;
                delete item.categoria;
                return item;
            });
            data.questoes = questoes;
        }
        else {
            //dissertiva nao precisa de mais nada, apenas enunciado
        }
        data.questao_id = this.props.match.params.id;
        if(this.props.evaluation==true){
            data.name=data.enunciado;
            data.text_questao=data.content_enunciado;
            data.keywords=data.palavras_chaves;
            this.props.saveQuestion(data);
            return;
        }
        this.setState({ loading_save: true, msgErro: '' });

        console.log(data);

        fetch(`${URL}api/professor/update_questao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify(data)
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        if(this.props.evaluation!=true){
        let type_return = new URLSearchParams(this.props.location.search).get('list');
        if (type_return !== undefined && type_return !== null) this.setState({ type_return: parseInt(type_return) });
        else if (type_return === null) this.setState({ type_return: 0 });
        this.get_questao(this.props.match.params.id);
        }
        else{
            this.parseQuestao({questao:this.props.questao,demanda:this.props.demanda})
        }
    }





    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        console.log(this.state.palavras_chaves)
        return (
            <div className={this.props.evaluation!=true?"row":""}>
                {this.state.redirect == true && <Redirect to={this.state.type_return == 1 ? '/questoes-recusadas' : (this.state.path + this.state.demanda_professor.id)} />}
                {this.state.show_modal_cadastro==false && <ModalFormula setImage={(image,html)=>{
                                        let  img =document.createElement('img')
                                        img.src=image
                                        console.log(this.editor_enunciado)
                                        this.editor_select.selection.insertNode(img)
                                        // this.editor_enunciado.selection.insertHTML(html)
                                                    // this.setState({ enunciado:'<p>'+html+'</p>' })

                                }} showModal={this.state.showModalFormula} setShowModal={(e) => this.setState({ showModalFormula: e })} />}

                <div className={this.props.evaluation!=true?"col-12":""}>
                    <div className={this.props.evaluation!=true?"card":""}>
                        <div className={this.props.evaluation!=true?"card-body":""}>
                            {this.props.evaluation!=true && <>
                            <div hidden id="text_enunciado">

                            </div>

                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar Questão</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite uma questão da Demanda "<b>{this.state.demanda.id}</b>", na Disciplina "<b>{this.state.categoria.name}</b>"</p>
                            </>}
                            
                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.props.evaluation!=true && <>
                                <hr/>
                                <p className="mb-2" style={{ fontSize: 16 }}><strong>Comentários</strong></p>
                                <ul className="list-unstyled">
                                    {this.state.comentarios.length == 0 && <li>Nenhum comentário adicionado</li>}
                                    {this.state.comentarios.map((comentario, index) => (
                                        <li
                                            key={index}
                                            className="d-flex align-items-center mb-3"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="me-3">
                                                <FaUserCircle className="fs-4 text-muted" />
                                            </div>
                                            <div className="flex-grow-1">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-${index}`}>
                                                            Criado em: {formatDate(comentario.created_at)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <strong className="text-muted me-2">
                                                            {comentario.user_name}
                                                        </strong>
                                                        <small className="text-muted">
                                                            {formatDate(comentario.created_at)}
                                                        </small>
                                                    </div>
                                                </OverlayTrigger>
                                                <p className="mb-0 text-dark">{comentario.comentario}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <p><strong>Status:</strong> {(this.state.statusAprovacao==null || this.state.statusAprovacao=='pendente')?<span style={{color:'darkgoldenrod'}}>Pendente</span>:(this.state.statusAprovacao=='aprovado'?<span style={{color:'green'}}>Aprovado</span>:<span style={{color:'red'}}>Recusado</span>)}</p>
                                
                                <hr/>

                                {this.state.msgErro != '' && <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                </>}

                                <ModalQuestao showModal={this.state.showModal} setShowModal={(e) => this.setState({ showModal: e })} questao={this.state.questaoSelect} />
                                {this.state.show_modal_cadastro && <ModalCadastroQuestao
                                    salvarQuestao={(e) => {
                                        let questoes = [...this.state.questoes];
                                        if (this.state.questaoSelect == null) {
                                            questoes.push(e);
                                        }
                                        else {
                                            questoes[this.state.index_select] = { ...questoes[this.state.index_select], ...e };
                                        }
                                        this.setState({ questoes, show_modal_cadastro: false });
                                    }}
                                    questaoSelect={this.state.questaoSelect}

                                    index_select={this.state.index_select}

                                    setShowModal={(e) => this.setState({ show_modal_cadastro: e })} showModal={this.state.show_modal_cadastro} categoria={this.state.categoria} />}
                                <Form onSubmit={(e) => this.salvar(e)} style={{ position: "relative" }}>
                                    {this.props.evaluation!=true && <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Tipo de questão <Attention /></Form.Label>
                                            <select className="form-select " onChange={(e) => {

                                                this.setState({ type_questao: e.target.value })
                                            }} value={this.state.type_questao}>
                                                <option
                                                    value={'simples'}
                                                >{'Questão objetiva'}</option>
                                                <option
                                                    value={'conjunto'}
                                                >{'Conjuntos de questões objetivas com enunciado em comum'}</option>
                                                <option
                                                    value={'dissertativa'}
                                                >{'Questão dissertativa'}</option>
                                            </select>
                                        </div>
                                    </InputGroup>}


                                    <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Nível de Escolaridade <Attention /></Form.Label>
                                            <select className="form-select " onChange={(e) => {

                                                this.setState({ escolaridade: e.target.value })
                                            }} value={this.state.escolaridade}>
                                                <option
                                                    value={''}
                                                >{'Selecione um nível de escolaridade'}</option>

                                                {this.state.escolaridades.map((item, id) => (
                                                    <option
                                                        key={id}
                                                        value={item}
                                                    >{item}</option>
                                                ))
                                                }
                                                {/* <option
                                                    value={'dissertativa'}
                                                >{'Questão dissertativa'}</option> */}
                                            </select>
                                        </div>
                                    </InputGroup>
                                    <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Nível de Dificuldade <Attention /></Form.Label>
                                            <select className="form-select " onChange={(e) => {

                                                this.setState({ dificuldade: e.target.value })
                                            }} value={this.state.dificuldade}>
                                                <option
                                                    value={''}
                                                >{'Selecione um nível de dificuldade'}</option>

                                                {this.state.dificuldades.map((item, id) => (
                                                    <option
                                                        key={id}
                                                        value={item}
                                                    >{item}</option>
                                                ))
                                                }

                                            </select>
                                        </div>
                                    </InputGroup>

                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Palavras chaves </Form.Label>
                                            <div>
                                                <Input
                                                    placeholder="Palavra chave"
                                                    value={this.state.keyword}
                                                    onChange={(e) => this.setState({ keyword: e.target.value })}
                                                />

                                                {<div className='row' style={{ marginLeft: 0, marginRight: 0 }}>
                                                    {this.state.palavras_chaves.map((item, id) => (
                                                        <div key={id} style={{ backgroundColor: '#f2f2f2', borderRadius: 10, padding: 5, width: 'auto', marginTop: 5, display: 'flex', marginRight: 5 }}>
                                                            <p style={{ marginBottom: 0, textAlign: 'center' }}>{item}</p>
                                                            {this.state.demanda?.keywords?.filter((item2, id2) => item2.name == item).length == 0 && <Close onClick={() => {
                                                                let palavras_chaves = this.state.palavras_chaves.filter((item2, id2) => id2 != id);
                                                                this.setState({ palavras_chaves });

                                                            }} style={{ cursor: 'pointer', width: '0.9rem', marginLeft: 3 }} />}
                                                        </div>
                                                    ))}
                                                </div>}

                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={(e) => {
                                                            if (this.state.keyword.trim().length == 0) {
                                                                return;
                                                            }
                                                            let palavras_chaves = [...this.state.palavras_chaves];
                                                            palavras_chaves.push(this.state.keyword);
                                                            this.setState({ palavras_chaves, keyword: '' });
                                                        }}
                                                        className="flex jcc align-center"
                                                    >Incluir paravra chave</Button>
                                                </div>

                                            </div>


                                        </div>
                                    </InputGroup>


                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label >Enunciado <Attention /> {this.state.loading_image_enunciado == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                            </div>}</Form.Label>
                                            <JoditEditor
                                                ref={this.editor_enunciado}
                                                value={this.state.enunciado}
                                                config={this.config_enunciado}
                                                height={400}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={newContent => this.setState({ enunciado: newContent })} // preferred to use only this option to update the content for performance reasons
                                            // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                            />
                                        </div>
                                    </InputGroup>
                                    {this.state.type_questao == 'simples' && <div>

                                        <InputGroup>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Número de alternativas <Attention /></Form.Label>
                                                <select className="form-select " onChange={(e) => {

                                                    this.setState({ numero_alternativa: e.target.value, correta: '' })
                                                }} value={this.state.numero_alternativa}>

                                                    {this.state.numero_alternativas.map((item, id) => (
                                                        <option
                                                            key={id}
                                                            value={item}
                                                        >{item}</option>
                                                    ))
                                                    }

                                                </select>
                                            </div>
                                        </InputGroup>
                                        <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label >Alternativa a) <Attention /> {this.state.loading_image_a == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                                </div>}</Form.Label>
                                                <JoditEditor
                                                    ref={this.editor_a}
                                                    value={this.state.alternativa_a}
                                                    config={this.config_a}
                                                    height={400}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onBlur={newContent => this.setState({ alternativa_a: newContent })} // preferred to use only this option to update the content for performance reasons
                                                // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                                />
                                            </div>
                                        </InputGroup>

                                        {this.state.numero_alternativa >= 2 && <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label >Alternativa b) <Attention /> {this.state.loading_image_b == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                                </div>}</Form.Label>
                                                <JoditEditor
                                                    ref={this.editor_b}
                                                    value={this.state.alternativa_b}
                                                    config={this.config_b}
                                                    height={400}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onBlur={newContent => this.setState({ alternativa_b: newContent })} // preferred to use only this option to update the content for performance reasons
                                                // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                                />
                                            </div>
                                        </InputGroup>}

                                        {this.state.numero_alternativa >= 3 && <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label >Alternativa c) <Attention /> {this.state.loading_image_c == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                                </div>}</Form.Label>
                                                <JoditEditor
                                                    ref={this.editor_c}
                                                    value={this.state.alternativa_c}
                                                    config={this.config_c}
                                                    height={400}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onBlur={newContent => this.setState({ alternativa_c: newContent })} // preferred to use only this option to update the content for performance reasons
                                                // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                                />
                                            </div>
                                        </InputGroup>}

                                        {this.state.numero_alternativa >= 4 && <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label >Alternativa d) <Attention /> {this.state.loading_image_d == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                                </div>}</Form.Label>
                                                <JoditEditor
                                                    ref={this.editor_d}
                                                    value={this.state.alternativa_d}
                                                    config={this.config_d}
                                                    height={400}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onBlur={newContent => this.setState({ alternativa_d: newContent })} // preferred to use only this option to update the content for performance reasons
                                                // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                                />
                                            </div>
                                        </InputGroup>}

                                        {this.state.numero_alternativa >= 5 && <InputGroup>

                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label >Alternativa e) <Attention /> {this.state.loading_image_e == true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                                </div>}</Form.Label>
                                                <JoditEditor
                                                    ref={this.editor_e}
                                                    value={this.state.alternativa_e}
                                                    config={this.config_e}
                                                    height={400}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onBlur={newContent => this.setState({ alternativa_e: newContent })} // preferred to use only this option to update the content for performance reasons
                                                // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                                />
                                            </div>
                                        </InputGroup>}

                                        <InputGroup>
                                            <div className="flex fdcolumn flex1 margin-bottom">
                                                <Form.Label>Qual a alternativa correta? <Attention /></Form.Label>
                                                <select className="form-select " onChange={(e) => {
                                                    console.log(e.target.value);
                                                    this.setState({ correta: e.target.value })
                                                }} value={this.state.correta}>
                                                    <option value="">Selecione uma alternativa correta</option>
                                                    {(this.state.corretas && this.state.corretas.length != 0) && this.state.corretas.map((value, index) => (
                                                        index <= this.state.numero_alternativa - 1 && <option
                                                            key={index}
                                                            value={value["id"]}
                                                        >{value["name"]}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </InputGroup>
                                    </div>}


                                    {this.state.type_questao == 'conjunto' && <div>
                                        <br />
                                        <InputGroup>
                                            <div className="flex justify-content-end flex-wrap mb-4" style={{ width: '100%' }}>



                                                <Button onClick={() => {
                                                    this.setState({ show_modal_cadastro: true, questaoSelect: null, index_select: -1 });
                                                }} variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar questão</p></Button>
                                            </div>
                                        </InputGroup></div>}

                                    {this.state.type_questao == 'conjunto' && <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom col-12">
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Enunciado</th>
                                                                {/* <th>Categoria</th> */}
                                                                <th>Alternativa Correta</th>

                                                                <th data-priority="3">Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.questoes.map((item, id) => (
                                                                <tr key={id}>
                                                                    <td ><p className='text_overflow'>{item.text_questao}</p></td>
                                                                    {/* <td >{item.categoria.name}</td> */}

                                                                    <td >{item.correta})</td>
                                                                    {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={item.arquivo} /></td> */}

                                                                    <td>
                                                                        <span
                                                                            onClick={() => {
                                                                                this.setState({ questaoSelect: { ...item,dificuldade:this.state.dificuldade,escolaridade:this.state.escolaridade, }, showModal: true });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-warning icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                            title="Gerenciar questão"
                                                                        >
                                                                            <BsFillEyeFill size={17} color="white" />
                                                                        </span>
                                                                        <span
                                                                            className="inline-flex align-center jcc bg-info icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", marginLeft: "2px" }}
                                                                            data-toggle="tooltip"
                                                                            title="Editar questão"
                                                                            onClick={(e) => {
                                                                                this.setState({ questaoSelect: { ...item }, index_select: id, show_modal_cadastro: true });
                                                                            }}
                                                                        >
                                                                            <MdModeEdit color="white" size={17} />
                                                                        </span>
                                                                        <span
                                                                            onClick={(e) => {
                                                                                let questoes = this.state.questoes.filter((item2, id2) => id != id2);
                                                                                this.setState({ questoes });
                                                                            }}
                                                                            className="inline-flex align-center jcc bg-danger icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                            title="Deletar questão"
                                                                        >
                                                                            <MdDelete size={17} color="white" />
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {this.state.questoes.length == 0 && <div className="row w-100">
                                                <div className="col-12">
                                                    <p style={{ textAlign: 'center' }}>Nenhuma questão adicionada</p>

                                                </div>
                                            </div>}
                                        </div>

                                    </InputGroup>}
                                    {this.state.type_questao!='conjunto' && <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Solução</Form.Label>
                                            <JoditEditor
                                                ref={this.editor_solucao}
                                                value={this.state.solucao}
                                                config={this.config_solucao}
                                                height={300}
                                                tabIndex={1}
                                                onBlur={newContent => this.setState({ solucao: newContent })}
                                            />
                                        </div>
                                    </InputGroup>}
                                    <br />
                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        {this.props.evaluation!=true ? <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            to={this.state.type_return == 1 ? '/questoes-recusadas' : ("/atribuicao/questoes/" + this.state.demanda_professor.id)}
                                        />:<div></div>}
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar questão"
                                            disabled={this.state.loading_image_enunciado == true || this.state.loading_image_a || this.state.loading_image_b || this.state.loading_image_c || this.state.loading_image_d || this.state.loading_image_e}
                                            loadingtext="Salvando questão"
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }




    load_configs(){
        this.config_enunciado = {
            height: 300,
            askBeforePasteHTML:false,
            askBeforePasteFromWord:false,
            defaultActionOnPaste:'insert_only_text',
            defaultActionOnPasteFromWord:'insert_only_text',
            pasteHTMLActionList:[
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList:[
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_enunciado,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            removeButtons:['video','fullsize','source','copyformat','about','link','classSpan'],
            toolbarAdaptive: false,
            extraButtons: [
                {
                    name: 'equation',
              tooltip: 'Equações',
                    iconURL: FormulaFx,
                    exec:  (editor)=> {
                        this.setState({showModalFormula:true});
                        this.editor_select=editor;
                        // if (editor.selection.isCollapsed()) {
                        // 	editor.execCommand('selectall');
                        // }
                        // editor.execCommand('copy');
                        // Jodit.Alert('Text in your clipboard');
                        console.log('clicou')
                    }
                }],
            // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_enunciado: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_enunciado: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            if(filename.includes('.mp4')){
                                this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                            }
                            else if(filename.includes('.mp3')){
                                this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                            }
                            else{
                                this.selection.insertImage(filename);
                            }
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_enunciado: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }



        this.config_a = {
            height: 300,
            askBeforePasteHTML:false,
            askBeforePasteFromWord:false,
            defaultActionOnPaste:'insert_only_text',
            defaultActionOnPasteFromWord:'insert_only_text',
            pasteHTMLActionList:[
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList:[
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_a,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            removeButtons:['video','fullsize','source','copyformat','about','link','classSpan'],
            toolbarAdaptive: false,
            extraButtons: [
                {
                    name: 'equation',
              tooltip: 'Equações',
                    iconURL: FormulaFx,
                    exec:  (editor)=> {
                        this.setState({showModalFormula:true});
                        this.editor_select=editor;
                        // if (editor.selection.isCollapsed()) {
                        // 	editor.execCommand('selectall');
                        // }
                        // editor.execCommand('copy');
                        // Jodit.Alert('Text in your clipboard');
                        console.log('clicou')
                    }
                }],
            // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_a: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_a: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            if(filename.includes('.mp4')){
                                this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                            }
                            else if(filename.includes('.mp3')){
                                this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                            }
                            else{
                                this.selection.insertImage(filename);
                            }
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_a: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }


        this.config_b = {
            height: 300,
            askBeforePasteHTML:false,
            askBeforePasteFromWord:false,
            defaultActionOnPaste:'insert_only_text',
            defaultActionOnPasteFromWord:'insert_only_text',
            pasteHTMLActionList:[
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList:[
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_b,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            removeButtons:['video','fullsize','source','copyformat','about','link','classSpan'],
            toolbarAdaptive: false,
            extraButtons: [
                {
                    name: 'equation',
              tooltip: 'Equações',
                    iconURL: FormulaFx,
                    exec:  (editor)=> {
                        this.setState({showModalFormula:true});
                        this.editor_select=editor;
                        // if (editor.selection.isCollapsed()) {
                        // 	editor.execCommand('selectall');
                        // }
                        // editor.execCommand('copy');
                        // Jodit.Alert('Text in your clipboard');
                        console.log('clicou')
                    }
                }],
            // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_b: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_b: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            if(filename.includes('.mp4')){
                                this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                            }
                            else if(filename.includes('.mp3')){
                                this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                            }
                            else{
                                this.selection.insertImage(filename);
                            }
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_b: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }





        this.config_c = {
            height: 300,
            askBeforePasteHTML:false,
            askBeforePasteFromWord:false,
            defaultActionOnPaste:'insert_only_text',
            defaultActionOnPasteFromWord:'insert_only_text',
            pasteHTMLActionList:[
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList:[
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_c,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            removeButtons:['video','fullsize','source','copyformat','about','link','classSpan'],
            toolbarAdaptive: false,
            extraButtons: [
                {
                    name: 'equation',
              tooltip: 'Equações',
                    iconURL: FormulaFx,
                    exec:  (editor)=> {
                        this.setState({showModalFormula:true});
                        this.editor_select=editor;
                        // if (editor.selection.isCollapsed()) {
                        // 	editor.execCommand('selectall');
                        // }
                        // editor.execCommand('copy');
                        // Jodit.Alert('Text in your clipboard');
                        console.log('clicou')
                    }
                }],
            // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_c: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_c: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            if(filename.includes('.mp4')){
                                this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                            }
                            else if(filename.includes('.mp3')){
                                this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                            }
                            else{
                                this.selection.insertImage(filename);
                            }
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_c: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }





        this.config_d = {
            height: 300,
            askBeforePasteHTML:false,
            askBeforePasteFromWord:false,
            defaultActionOnPaste:'insert_only_text',
            defaultActionOnPasteFromWord:'insert_only_text',
            pasteHTMLActionList:[
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList:[
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_d,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            removeButtons:['video','fullsize','source','copyformat','about','link','classSpan'],
            toolbarAdaptive: false,
            extraButtons: [
                {
                    name: 'equation',
              tooltip: 'Equações',
                    iconURL: FormulaFx,
                    exec:  (editor)=> {
                        this.setState({showModalFormula:true});
                        this.editor_select=editor;
                        // if (editor.selection.isCollapsed()) {
                        // 	editor.execCommand('selectall');
                        // }
                        // editor.execCommand('copy');
                        // Jodit.Alert('Text in your clipboard');
                        console.log('clicou')
                    }
                }],
            // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_d: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_d: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            if(filename.includes('.mp4')){
                                this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                            }
                            else if(filename.includes('.mp3')){
                                this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                            }
                            else{
                                this.selection.insertImage(filename);
                            }
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_d: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }


        this.config_e = {
            height: 300,
            askBeforePasteHTML:false,
            askBeforePasteFromWord:false,
            defaultActionOnPaste:'insert_only_text',
            defaultActionOnPasteFromWord:'insert_only_text',
            pasteHTMLActionList:[
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            pasteFromWordActionList:[
                { value: 'insert_only_text', text: 'Insert only Text' }
            ],
            readonly: this.state.loading_image_e,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            removeButtons:['video','fullsize','source','copyformat','about','link','classSpan'],
            toolbarAdaptive: false,
            extraButtons: [
                {
                    name: 'equation',
              tooltip: 'Equações',
                    iconURL: FormulaFx,
                    exec:  (editor)=> {
                        this.setState({showModalFormula:true});
                        this.editor_select=editor;
                        // if (editor.selection.isCollapsed()) {
                        // 	editor.execCommand('selectall');
                        // }
                        // editor.execCommand('copy');
                        // Jodit.Alert('Text in your clipboard');
                        console.log('clicou')
                    }
                }],
            // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image_e: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image_e: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            if(filename.includes('.mp4')){
                                this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                            }
                            else if(filename.includes('.mp3')){
                                this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                            }
                            else{
                                this.selection.insertImage(filename);
                            }
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image_e: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }

        this.config_solucao = {
                    height: 300,
                    askBeforePasteHTML: false,
                    askBeforePasteFromWord: false,
                    defaultActionOnPaste: 'insert_only_text',
                    defaultActionOnPasteFromWord: 'insert_only_text',
                    pasteHTMLActionList: [
                        { value: 'insert_only_text', text: 'Insert only Text' }
                    ],
                    pasteFromWordActionList: [
                        { value: 'insert_only_text', text: 'Insert only Text' }
                    ],
                    readonly: this.state.loading_image_solucao,
                    language: 'pt_br',
                    toolbarButtonSize: 'large',
                    removeButtons: ['video', 'fullsize', 'source', 'copyformat', 'about', 'link', 'classSpan'],
                    toolbarAdaptive: false,
                    extraButtons: [
                        {
                            name: 'equation',
                      tooltip: 'Equações',
                            iconURL: FormulaFx,
                            exec:  (editor)=> {
                                this.setState({showModalFormula:true});
                                this.editor_select=editor;
                                // if (editor.selection.isCollapsed()) {
                                // 	editor.execCommand('selectall');
                                // }
                                // editor.execCommand('copy');
                                // Jodit.Alert('Text in your clipboard');
                                console.log('clicou')
                            }
                        }],
                    // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
                    uploader: {
                        url: API_URL + 'faculdade/upload_image',  //your upload api url
                        insertImageAsBase64URI: false, //not inster base64
                        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                        headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                        filesVariableName: function (t) {
                            return 'imagem';
                        }, //"files",
                        withCredentials: false,
                        pathVariableName: 'path',
                        //   format: 'json',
                        method: 'POST',
                        prepareData: (formdata) => {
                            console.log(formdata)
                            this.setState({ loading_image_solucao: true });
                            return formdata;
                        },
                        isSuccess: (e) => {
                            console.log(e);
        
                            return 'Upload realizado com sucesso';
                        },
                        getMessage: (e) => {
                            console.log(e);
        
                            return e;
                        },
                        process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                            let files = [];
                            console.log(resp);
                            files.unshift(STORAGE_URL + resp.imagem);
                            this.setState({ loading_image_solucao: false });
        
                            return {
                                files: files,
        
                            };
                        },
                        error: (elem, e) => {
                            // elem.j.e.fire('errorMessage', e.message, 'error', 4000);
        
                            console.log(e);
                            console.log(elem);
                            console.log('Bearer ' + localStorage.getItem('token'));
                        },
                        defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                            if (resp.files && resp.files.length) {
                                resp.files.forEach((filename, index) => { //edetor insertimg function
                                    if (filename.includes('.mp4')) {
                                        this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                                    }
                                    else if (filename.includes('.mp3')) {
                                        this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                                    }
                                    else {
                                        this.selection.insertImage(filename);
                                    }
                                });
                            }
        
                            // debugger;
        
                        },
                        defaultHandlerError: (elem, e) => {
                            // elem.j.e.fire('errorMessage', e.message);
                            console.log(e);
                            console.log(elem);
                            this.setState({ loading_image_solucao: false });
        
                        },
                        //   contentType: function (e) {
                        //     return (
        
                        //       'application/x-www-form-urlencoded; charset=UTF-8'
                        //     );
                        //   },
                    },
                }
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarQuestao);



// Library Imports
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { BsArrowRightCircle, BsFillEyeFill } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

// Utils Imports
import { logout } from "../../actions/AppActions";
import formatDate from "../../Auxiliar/formatDate";
import { get, post } from "../../Services/api";
import Search from "../ReusableComponents/Search";
import { Pagination } from "@material-ui/lab";

const SuccessModal = ({ show, handleClose, handleTableUpdate }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Sucesso!</Modal.Title>
    </Modal.Header>
    <Modal.Body>Questão avaliada com sucesso!</Modal.Body>
    <Modal.Footer>
      <Button
        variant="primary"
        onClick={() => {
          handleClose();
          handleTableUpdate();
        }}
      >
        Fechar
      </Button>
    </Modal.Footer>
  </Modal>
);

const QuestoesRecusadas = ({ token, user }) => {
  const [demandas, setDemandas] = useState([]);
  const [questoes, setQuestoes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comentarios, setComentarios] = useState([]);
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [questaoId, setQuestaoId] = useState(null);
  const pesquisarTimeout = useRef();
  const [pagination, setPagination] = useState({current_page:1,last_page:1});
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('recusado');

  useEffect(() => {
    fetchQuestoes(1,search,statusFilter)
  }, []);

  const fetchQuestoes = async (page=1,search='',statusFilter='recusado') => {
    setLoading(true);
    try {
      const resp = await get(
        `api/professor/get_all_questoes_demanda_user?page=${page}&search=${search}&status=${statusFilter}`,
        token
      );
      setQuestoes(resp.questoes);
      setPagination(resp.pagination);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchComentarios = async (questaoId) => {
    setIsCommentsLoading(true);
    try {
      const res = await get(`api/professor/get_comentario/${questaoId}`, token);
      setComentarios(
        res.map((item) => ({
          comentario: item.comentario,
          user_name: item.user_name,
          created_at: item.created_at,
        }))
      );
    } catch (error) {
    } finally {
      setIsCommentsLoading(false);
    }
  };

  const editStatusQuestao = async (status, questaoId) => {
    try {
      await post(
        `api/professor/update_status_questao/${questaoId}`,
        { status },
        token
      );
      setShowSuccessModal(true);
    } catch (error) {}
  };

  const handleOpenModal = (questaoId) => {
    setIsModalOpen(true);
    setQuestaoId(questaoId);
    fetchComentarios(questaoId);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const handleSubmitStatus = () => {
    editStatusQuestao("pendente", questaoId);
    handleCloseModal();
  };

  const handleTableUpdate = () => window.location.reload();

  if (redirect) {
    return <Redirect to="/error" />;
  }

  const questoesFiltradas = questoes

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h4 className="mt-0 header-title">Lista de Questões Recusadas</h4>
            <p>Aqui são listados todas as questões recusadas do seu sistema</p>
            <div className="row">
                <div className="flex jcsb flex-wrap mb-1">

                  <Search placeholder="Pesquisar pelo enunciado" ValueChange={value => {
                    clearTimeout(pesquisarTimeout.current);
                    pesquisarTimeout.current = setTimeout(() => {
                      let page = { ...pagination };
                      page.current_page = 1;
                      setPagination(page);
                      setSearch(value);
                      setLoading(true);
                      fetchQuestoes(1, value,statusFilter);
                    }, 2000);
                  }} />


                  




                </div>
                <div className="flex flex-wrap">
                  <strong>Status:&nbsp;&nbsp;&nbsp;</strong>  
                  {["pendente","aprovado", "recusado","all"].map((option) => (
                    <div
                      key={option}
                      className="form-check align-items-center mb-2 me-4"
                    >
                      <input
                        type="radio"
                        name={"dsada"+option}
                        id={"dsada"+option}
                        className="form-check-input me-2"
                        value={option}
                        checked={statusFilter === option}
                        onChange={(e)=>{
                          setStatusFilter(e.target.value);
                          setLoading(true);
                          fetchQuestoes(pagination.page,search,e.target.value)
                        }}
                      />
                      <label htmlFor={option} className="form-check-label">
                        {option === "pendente"?"Pendentes": (option === "aprovado" ? "Aprovadas" : (option=='recusado'?"Desaprovadas":"Todas"))}
                      </label>
                    </div>
                  ))}
                  </div>
            {loading ? (
                  <div className="d-flex justify-content-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <div >

                    <div className="table-rep-plugin">
                      <div className="table-responsive mb-0">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Enunciado</th>
                              <th>Disciplina</th>
                              <th>Nome do elaborador</th>
                              <th>Identificador da demanda</th>
                              <th>Status</th>
                              <th>Data da última atualização</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {questoesFiltradas.length === 0 ? (
                              <tr>
                                <td colSpan="7">
                                  {/* <div
                                    className="alert alert-warning"
                                    role="alert"
                                  > */}
                                  Nenhuma questão encontrada.
                                  {/* </div> */}
                                </td>
                              </tr>
                            ) : (
                              questoesFiltradas.map((questao, index) => (
                                <tr key={index}>
                                  <td>{questao.text_questao}</td>
                                  <td>{questao.categoria.name}</td>
                                  <td>{questao.user.name}</td>

                                  <td>{questao.demanda.id}</td>
                                  <td>{(questao.statusAprovacao==null || questao.statusAprovacao=='pendente')?<span style={{color:'darkgoldenrod'}}>Pendente</span>:(questao.statusAprovacao=='aprovado'?<span style={{color:'green'}}>Aprovado</span>:<span style={{color:'red'}}>Recusado</span>)}</td>
                                  <td>{formatDate(questao.updated_at)}</td>
                                  <td>
                                    <Link
                                      to={`/atribuicao/questoes/edit/${questao.id}?list=1`}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <span
                                        className="inline-flex align-center jcc bg-info icon"
                                        style={{
                                          width: "2em",
                                          height: "2em",
                                          borderRadius: "7px",
                                          marginRight: "2px",
                                        }}
                                        title="Avaliar"
                                      >
                                        <BsArrowRightCircle
                                          size={17}
                                          color="white"
                                        />
                                      </span>
                                    </Link>

                                    {/* <span
                                      onClick={() => this.openModal(questao)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span
                                        className="inline-flex align-center jcc bg-info icon"
                                        style={{
                                          width: "2em",
                                          height: "2em",
                                          borderRadius: "7px",
                                          marginRight: "2px",
                                        }}
                                        data-toggle="tooltip"
                                        title="Avaliar Questão"
                                      >
                                        <FaStar color="white" size={17} />
                                      </span>
                                    </span> */}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <Pagination
                      className={(pagination.last_page == 1 ? "hide" : "")}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      count={pagination.last_page}
                      value={pagination.current_page}
                      onChange={(_, value) => {
                        console.log(_, value);
                        let page = { ...pagination };
                        page.current_page = value;
                        setPagination(page)
                        setLoading(true)
                        fetchQuestoes(value, search,statusFilter);
                      }}
                    />
                  </div>
                )}
                  </div>

            {/* <div className="table-responsive mb-0">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress />
                </div>
              ) : (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Nome do elaborador</th>
                      <th>Nome da demanda</th>
                      <th>Data de criação</th>
                      <th>Data de última alteração</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {questoesFiltradas.length === 0 ? (
                      <tr>
                        <td colSpan="5">
                            Nenhuma questão recusada.
                        </td>
                      </tr>
                    ) : (
                      questoesFiltradas.map((questao, index) => (
                        <tr key={index}>
                          <td>{questao.user.name}</td>
                          <td>{questao.demanda.name}</td>
                          <td>{formatDate(questao.created_at)}</td>
                          <td>{formatDate(questao.updated_at)}</td>
                          <td>
                            <Link
                              to={`/questoes-recusadas/show/${questao.id}`}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <span
                                className="inline-flex align-center jcc bg-warning icon"
                                style={{
                                  width: "2em",
                                  height: "2em",
                                  borderRadius: "7px",
                                  marginRight: "2px",
                                }}
                                title="Gerenciar"
                              >
                                <BsFillEyeFill size={17} color="white" />
                              </span>
                            </Link>

                            <span
                              onClick={() => handleOpenModal(questao.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                className="inline-flex align-center jcc bg-info icon"
                                style={{
                                  width: "2em",
                                  height: "2em",
                                  borderRadius: "7px",
                                  marginRight: "2px",
                                }}
                                data-toggle="tooltip"
                                title="Avaliar Questão"
                              >
                                <FaStar color="white" size={17} />
                              </span>
                            </span>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div> */}
          </div>
        </div>
      </div>

      <Modal show={isModalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Avaliar Questão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isCommentsLoading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <h5>Comentários</h5>
              <ul className="list-unstyled">
                {comentarios.map((comentario, index) => (
                  <li key={index} className="d-flex align-items-center mb-3">
                    <div className="me-3">
                      <i className="bi bi-person-circle fs-4 text-muted"></i>
                    </div>
                    <div className="flex-grow-1">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            Criado em: {formatDate(comentario.created_at)}
                          </Tooltip>
                        }
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <strong className="text-muted">
                            {comentario.user_name}
                          </strong>
                          <small className="text-muted">
                            {formatDate(comentario.created_at)}
                          </small>
                        </div>
                      </OverlayTrigger>
                      <p>{comentario.comentario}</p>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="d-flex justify-content-end">
                <Button variant="success" onClick={handleSubmitStatus}>
                  Enviar para Avaliação
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <SuccessModal
        show={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        handleTableUpdate={handleTableUpdate}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(QuestoesRecusadas);
